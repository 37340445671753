@import "../node_modules/@audi/audi-ui/src/index";
@import "../node_modules/normalize.css";

@font-face {
  font-family: "AudiTypeScreen";
  src: url('assets/fonts/audi/AudiTypeScreen-Normal.eot');
  src: url('assets/fonts/audi/AudiTypeScreen-Normal.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/audi/AudiTypeScreen-Latin-Normal.woff') format('woff'),
  url('assets/fonts/audi/AudiTypeScreen-Normal.ttf') format('truetype'),
  url('assets/fonts/audi/AudiTypeScreen-Normal.svg#Audi-Bold') format('svg');
}

@font-face {
  font-family: "AudiTypeScreenLight";
  src: url('assets/fonts/audi/AudiTypeScreen-Light.eot');
  src: url('assets/fonts/audi/AudiTypeScreen-Light.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/audi/AudiTypeScreen-Latin-Light.woff') format('woff'),
  url('assets/fonts/audi/AudiTypeScreen-Light.ttf') format('truetype'),
  url('assets/fonts/audi/AudiTypeScreen-Light.svg#Audi-Bold') format('svg');
}

@font-face {
  font-family: "AudiTypeScreenBold";
  src: url('assets/fonts/audi/AudiTypeScreen-Bold.eot');
  src: url('assets/fonts/audi/AudiTypeScreen-Bold.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/audi/AudiTypeScreen-Latin-Bold.woff') format('woff'),
  url('assets/fonts/audi/AudiTypeScreen-Bold.ttf') format('truetype'),
  url('assets/fonts/audi/AudiTypeScreen-Bold.svg#Audi-Bold') format('svg');
}

@font-face {
  font-family: "AudiTypeExtended";
  src: url('assets/fonts/audi/AudiType-ExtendedNormal_08.eot');
  src: url('assets/fonts/audi/AudiType-ExtendedNormal_08.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/audi/AudiType-Latin-ExtendedNormal.woff') format('woff'),
  url('assets/fonts/audi/AudiType-ExtendedNormal.ttf') format('truetype'),
  url('assets/fonts/audi/AudiType-ExtendedNormal_08.svg#Audi-Extened-Normal') format('svg');
}

@font-face {
  font-family: "AudiTypeExtendedBold";
  src: url('assets/fonts/audi/AudiType-ExtendedBold_08.eot');
  src: url('assets/fonts/audi/AudiType-ExtendedBold_08.eot?#iefix') format('embedded-opentype'),
  url('assets/fonts/audi/AudiType-Latin-ExtendedBold.woff') format('woff'),
  url('assets/fonts/audi/AudiType-ExtendedBold.ttf') format('truetype'),
  url('assets/fonts/audi/AudiType-ExtendedBold_08.svg#Audi-Extened-Bold') format('svg');
}
