@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}
.form {
  form {
    width: 100%;
  }

  &__wrap, &__success {

    flex-wrap: wrap;
    background: #f2f2f2;
    min-height: 100vh;
    width: 100%;

    @media(min-width: 768px){
      display: flex;
    }
  }

  &__sidebar {
    width: 50%;
    background-size: cover;
    position: relative;
    display: flex;
    padding-top: 90px;

    @media(max-width: 767px) {
      width: 100%;
      position: relative;
      padding-top: 0;
      height: 220px;
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.7);
    }

    .steps {
      width: 100%;
      position: relative;
      z-index: 1;
      padding: 7.5% 15% 15%;

      @media(max-width: 767px) {
        padding: 0 7.5%;
        height: 220px;
        box-sizing: border-box;
      }

      h4 {
        color: #fff;
        @media(max-width: 767px) {
          position: absolute;
          bottom: 15%;
          padding: 0 7.5%;
          left:0;
        }

      }

      ul {
        padding: 2rem 0 0 0;

        @media(max-width: 767px) {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        li {
          padding: 0 0 0 52px;
          color: #fff;
          list-style: none;
          line-height: 1;
          margin-bottom: 52px;
          opacity: 0.5;
          position: relative;
          transition: all 0.2s ease-in-out ;

          @media(max-width: 767px) {
            font-size: 0;
          }

          &:before {
            content: '';
            position: absolute;
            width: 8px;
            height: 8px;
            border: 1px solid #fff;
            border-radius: 50%;
            top: 50%;
            transform: translateY(-50%);
            left: 11px;
            z-index: 1;
          }

          &:after {
            content: '';
            position: absolute;
            width: 32px;
            height: 32px;
            background-color: #ffffff;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 50%;
            display: none;
          }

          &.done {
            opacity: 1;
            animation: fadeIn 0.5s ease-in-out;

            &:before {
              content: url("../assets/img/select.svg");
              width: 30px;
              height: 30px;
              left: 0;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            &:after {
              display: block;
            }
          }

          &.active {
            opacity: 1;
            animation: fadeIn 0.5s ease-in-out;

            &:before {
              background: #000;
              border-color: #000;
            }

            &:after {
              display: block;
            }
          }
        }
      }
    }
  }

  &__content {
    width: 50%;
    display: flex;
    padding-top: 90px;
    animation: fadeIn 0.5s ease-in-out;

    @media(max-width: 767px) {
      width: 100%;
      padding-top: 0;
    }

    > div {
      padding: 7.5%;
      width: 100%;
    }

    .cols {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .aui-textfield {
        width: 40%;

        @media(max-width: 767px) {
          width: 100%;
        }
      }

      @media(max-width: 576px) {
        .col-helper {
          text-align: center;
          width: 100%;
        }
      }
    }

    .buttons-wrap {
      margin-bottom: 2rem;
      margin-top: 35px;

      button:first-child {
        margin-right: 5px;
      }

      @media(max-width: 576px) {

        button {
          width: 100%;
          margin: 0 0 5px 0;
        }

      }
    }

    .textarea-letter-count {
      display: flex;
      justify-content: space-between;

      span:last-child {
        padding-right: 2rem;
      }
    }

    .aui-caption {
      opacity: 0.6;
    }

    .summary {
      &__item {
        margin-bottom: 2rem;
        position: relative;

        a {
          position: absolute;
          top: 0;
          right: 0;
        }

        p {
          margin: 0 0 6px 0;
          position: relative;

          strong {
            font-weight: 600;
          }
        }

        span {
          display: flex;
        }
      }
    }

    .checkbox {
      &__wrap {
        .aui-checkbox {
          margin-bottom: 8px;
          line-height: 1.1 !important;

          .aui-checkbox__label {
            p {
              margin: 0;
            }

            a {
              text-decoration: none;
              padding-bottom: 0.06125em;
              border-bottom: 1px solid rgba(0, 0, 0, 0.3);
              color: inherit;
              font-weight: 400;
              transition: color 0.3s linear, border 0.3s linear;

              &:hover {
                color: #000000;
                border-bottom-color: #000000;
              }

              &:focus {
                outline: none;
              }
            }


          }


          &.error {
            .aui-checkbox__label {
              color: #bb0a30;
            }

            .aui-checkbox__box {
              border-color: #bb0a30;
            }
          }
        }
      }
    }

    .time-select {
      padding: 0;

      li {
        list-style: none;
        display: inline-block;
        background-color: #cccccc;
        color: rgba(0, 0, 0, 0.5);
        font-size: 13px;
        font-weight: 400;
        line-height: 34px;
        margin-right: 4px;
        margin-bottom: 4px;
        padding: 0 14px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          opacity: 0.8;
          transition: all 0.2s ease-in-out;
        }
      }

    }

    .model-image {
      img {
        display: block;
        width: 100%;
        max-width: 258px;
        height: auto;
      }
    }

    .summary-image {
      display: block;
      width: 100%;
      max-width: 282px;
    }

    &.min-padding {
        padding-top: 0;

        > div {
            padding-top: 3%;
            padding-bottom: 2%;
        }
    }
  }
}

.aui-select.aui-select--floating-label-no-padding-top {
    padding-top: 0 !important;
}

.aui-textfield {
  &__input {
    min-height: 1.5rem;
  }

  &__error {
    opacity: 0;

    &.visible {
      opacity: 1;
      transition: 0.2s ease-in-out;
    }
  }
}

.aui-select {
  &__input {
    margin-bottom: 10px;
  }
}

.aui-small a {
    color: #000;
    text-decoration: underline;
}
